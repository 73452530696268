!(function(t) {
  var e,
    r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/',
    o = '='
  function E(t, e, i) {
    null != t &&
      ('number' == typeof t
        ? this.fromNumber(t, e, i)
        : null == e && 'string' != typeof t
        ? this.fromString(t, 256)
        : this.fromString(t, e))
  }
  function b() {
    return new E(null)
  }
  ;(e =
    'Microsoft Internet Explorer' == navigator.appName
      ? ((E.prototype.am = function(t, e, i, n, r, o) {
          for (var a = 32767 & e, s = e >> 15; 0 <= --o; ) {
            var h = 32767 & this[t],
              c = this[t++] >> 15,
              l = s * h + c * a
            ;(r =
              ((h = a * h + ((32767 & l) << 15) + i[n] + (1073741823 & r)) >>> 30) +
              (l >>> 15) +
              s * c +
              (r >>> 30)),
              (i[n++] = 1073741823 & h)
          }
          return r
        }),
        30)
      : 'Netscape' != navigator.appName
      ? ((E.prototype.am = function(t, e, i, n, r, o) {
          for (; 0 <= --o; ) {
            var a = e * this[t++] + i[n] + r
            ;(r = Math.floor(a / 67108864)), (i[n++] = 67108863 & a)
          }
          return r
        }),
        26)
      : ((E.prototype.am = function(t, e, i, n, r, o) {
          for (var a = 16383 & e, s = e >> 14; 0 <= --o; ) {
            var h = 16383 & this[t],
              c = this[t++] >> 14,
              l = s * h + c * a
            ;(r = ((h = a * h + ((16383 & l) << 14) + i[n] + r) >> 28) + (l >> 14) + s * c),
              (i[n++] = 268435455 & h)
          }
          return r
        }),
        28)),
    (E.prototype.DB = e),
    (E.prototype.DM = (1 << e) - 1),
    (E.prototype.DV = 1 << e)
  ;(E.prototype.FV = Math.pow(2, 52)), (E.prototype.F1 = 52 - e), (E.prototype.F2 = 2 * e - 52)
  var i,
    n,
    a = '0123456789abcdefghijklmnopqrstuvwxyz',
    c = new Array()
  for (i = '0'.charCodeAt(0), n = 0; n <= 9; ++n) c[i++] = n
  for (i = 'a'.charCodeAt(0), n = 10; n < 36; ++n) c[i++] = n
  for (i = 'A'.charCodeAt(0), n = 10; n < 36; ++n) c[i++] = n
  function h(t) {
    return a.charAt(t)
  }
  function s(t) {
    var e = b()
    return e.fromInt(t), e
  }
  function V(t) {
    var e,
      i = 1
    return (
      0 != (e = t >>> 16) && ((t = e), (i += 16)),
      0 != (e = t >> 8) && ((t = e), (i += 8)),
      0 != (e = t >> 4) && ((t = e), (i += 4)),
      0 != (e = t >> 2) && ((t = e), (i += 2)),
      0 != (e = t >> 1) && ((t = e), (i += 1)),
      i
    )
  }
  function l(t) {
    this.m = t
  }
  function m(t) {
    ;(this.m = t),
      (this.mp = t.invDigit()),
      (this.mpl = 32767 & this.mp),
      (this.mph = this.mp >> 15),
      (this.um = (1 << (t.DB - 15)) - 1),
      (this.mt2 = 2 * t.t)
  }
  function u() {
    ;(this.i = 0), (this.j = 0), (this.S = new Array())
  }
  ;(l.prototype.convert = function(t) {
    return t.s < 0 || 0 <= t.compareTo(this.m) ? t.mod(this.m) : t
  }),
    (l.prototype.revert = function(t) {
      return t
    }),
    (l.prototype.reduce = function(t) {
      t.divRemTo(this.m, null, t)
    }),
    (l.prototype.mulTo = function(t, e, i) {
      t.multiplyTo(e, i), this.reduce(i)
    }),
    (l.prototype.sqrTo = function(t, e) {
      t.squareTo(e), this.reduce(e)
    }),
    (m.prototype.convert = function(t) {
      var e = b()
      return (
        t.abs().dlShiftTo(this.m.t, e),
        e.divRemTo(this.m, null, e),
        t.s < 0 && 0 < e.compareTo(E.ZERO) && this.m.subTo(e, e),
        e
      )
    }),
    (m.prototype.revert = function(t) {
      var e = b()
      return t.copyTo(e), this.reduce(e), e
    }),
    (m.prototype.reduce = function(t) {
      for (; t.t <= this.mt2; ) t[t.t++] = 0
      for (var e = 0; e < this.m.t; ++e) {
        var i = 32767 & t[e],
          n = (i * this.mpl + (((i * this.mph + (t[e] >> 15) * this.mpl) & this.um) << 15)) & t.DM
        for (t[(i = e + this.m.t)] += this.m.am(0, n, t, e, 0, this.m.t); t[i] >= t.DV; )
          (t[i] -= t.DV), t[++i]++
      }
      t.clamp(), t.drShiftTo(this.m.t, t), 0 <= t.compareTo(this.m) && t.subTo(this.m, t)
    }),
    (m.prototype.mulTo = function(t, e, i) {
      t.multiplyTo(e, i), this.reduce(i)
    }),
    (m.prototype.sqrTo = function(t, e) {
      t.squareTo(e), this.reduce(e)
    }),
    (E.prototype.copyTo = function(t) {
      for (var e = this.t - 1; 0 <= e; --e) t[e] = this[e]
      ;(t.t = this.t), (t.s = this.s)
    }),
    (E.prototype.fromInt = function(t) {
      ;(this.t = 1),
        (this.s = t < 0 ? -1 : 0),
        0 < t ? (this[0] = t) : t < -1 ? (this[0] = t + this.DV) : (this.t = 0)
    }),
    (E.prototype.fromString = function(t, e) {
      var i
      if (16 == e) i = 4
      else if (8 == e) i = 3
      else if (256 == e) i = 8
      else if (2 == e) i = 1
      else if (32 == e) i = 5
      else {
        if (4 != e) return void this.fromRadix(t, e)
        i = 2
      }
      ;(this.t = 0), (this.s = 0)
      for (var n, r, o = t.length, a = !1, s = 0; 0 <= --o; ) {
        var h = 8 == i ? 255 & t[o] : ((n = o), null == (r = c[t.charCodeAt(n)]) ? -1 : r)
        h < 0
          ? '-' == t.charAt(o) && (a = !0)
          : ((a = !1),
            0 == s
              ? (this[this.t++] = h)
              : s + i > this.DB
              ? ((this[this.t - 1] |= (h & ((1 << (this.DB - s)) - 1)) << s),
                (this[this.t++] = h >> (this.DB - s)))
              : (this[this.t - 1] |= h << s),
            (s += i) >= this.DB && (s -= this.DB))
      }
      8 == i &&
        0 != (128 & t[0]) &&
        ((this.s = -1), 0 < s && (this[this.t - 1] |= ((1 << (this.DB - s)) - 1) << s)),
        this.clamp(),
        a && E.ZERO.subTo(this, this)
    }),
    (E.prototype.clamp = function() {
      for (var t = this.s & this.DM; 0 < this.t && this[this.t - 1] == t; ) --this.t
    }),
    (E.prototype.dlShiftTo = function(t, e) {
      var i
      for (i = this.t - 1; 0 <= i; --i) e[i + t] = this[i]
      for (i = t - 1; 0 <= i; --i) e[i] = 0
      ;(e.t = this.t + t), (e.s = this.s)
    }),
    (E.prototype.drShiftTo = function(t, e) {
      for (var i = t; i < this.t; ++i) e[i - t] = this[i]
      ;(e.t = Math.max(this.t - t, 0)), (e.s = this.s)
    }),
    (E.prototype.lShiftTo = function(t, e) {
      var i,
        n = t % this.DB,
        r = this.DB - n,
        o = (1 << r) - 1,
        a = Math.floor(t / this.DB),
        s = (this.s << n) & this.DM
      for (i = this.t - 1; 0 <= i; --i)
        (e[i + a + 1] = (this[i] >> r) | s), (s = (this[i] & o) << n)
      for (i = a - 1; 0 <= i; --i) e[i] = 0
      ;(e[a] = s), (e.t = this.t + a + 1), (e.s = this.s), e.clamp()
    }),
    (E.prototype.rShiftTo = function(t, e) {
      e.s = this.s
      var i = Math.floor(t / this.DB)
      if (i >= this.t) e.t = 0
      else {
        var n = t % this.DB,
          r = this.DB - n,
          o = (1 << n) - 1
        e[0] = this[i] >> n
        for (var a = i + 1; a < this.t; ++a)
          (e[a - i - 1] |= (this[a] & o) << r), (e[a - i] = this[a] >> n)
        0 < n && (e[this.t - i - 1] |= (this.s & o) << r), (e.t = this.t - i), e.clamp()
      }
    }),
    (E.prototype.subTo = function(t, e) {
      for (var i = 0, n = 0, r = Math.min(t.t, this.t); i < r; )
        (n += this[i] - t[i]), (e[i++] = n & this.DM), (n >>= this.DB)
      if (t.t < this.t) {
        for (n -= t.s; i < this.t; ) (n += this[i]), (e[i++] = n & this.DM), (n >>= this.DB)
        n += this.s
      } else {
        for (n += this.s; i < t.t; ) (n -= t[i]), (e[i++] = n & this.DM), (n >>= this.DB)
        n -= t.s
      }
      ;(e.s = n < 0 ? -1 : 0),
        n < -1 ? (e[i++] = this.DV + n) : 0 < n && (e[i++] = n),
        (e.t = i),
        e.clamp()
    }),
    (E.prototype.multiplyTo = function(t, e) {
      var i = this.abs(),
        n = t.abs(),
        r = i.t
      for (e.t = r + n.t; 0 <= --r; ) e[r] = 0
      for (r = 0; r < n.t; ++r) e[r + i.t] = i.am(0, n[r], e, r, 0, i.t)
      ;(e.s = 0), e.clamp(), this.s != t.s && E.ZERO.subTo(e, e)
    }),
    (E.prototype.squareTo = function(t) {
      for (var e = this.abs(), i = (t.t = 2 * e.t); 0 <= --i; ) t[i] = 0
      for (i = 0; i < e.t - 1; ++i) {
        var n = e.am(i, e[i], t, 2 * i, 0, 1)
        ;(t[i + e.t] += e.am(i + 1, 2 * e[i], t, 2 * i + 1, n, e.t - i - 1)) >= e.DV &&
          ((t[i + e.t] -= e.DV), (t[i + e.t + 1] = 1))
      }
      0 < t.t && (t[t.t - 1] += e.am(i, e[i], t, 2 * i, 0, 1)), (t.s = 0), t.clamp()
    }),
    (E.prototype.divRemTo = function(t, e, i) {
      var n = t.abs()
      if (!(n.t <= 0)) {
        var r = this.abs()
        if (r.t < n.t) return null != e && e.fromInt(0), void (null != i && this.copyTo(i))
        null == i && (i = b())
        var o = b(),
          a = this.s,
          s = t.s,
          h = this.DB - V(n[n.t - 1])
        0 < h ? (n.lShiftTo(h, o), r.lShiftTo(h, i)) : (n.copyTo(o), r.copyTo(i))
        var c = o.t,
          l = o[c - 1]
        if (0 != l) {
          var m = l * (1 << this.F1) + (1 < c ? o[c - 2] >> this.F2 : 0),
            u = this.FV / m,
            d = (1 << this.F1) / m,
            p = 1 << this.F2,
            f = i.t,
            g = f - c,
            v = null == e ? b() : e
          for (
            o.dlShiftTo(g, v),
              0 <= i.compareTo(v) && ((i[i.t++] = 1), i.subTo(v, i)),
              E.ONE.dlShiftTo(c, v),
              v.subTo(o, o);
            o.t < c;

          )
            o[o.t++] = 0
          for (; 0 <= --g; ) {
            var y = i[--f] == l ? this.DM : Math.floor(i[f] * u + (i[f - 1] + p) * d)
            if ((i[f] += o.am(0, y, i, g, 0, c)) < y)
              for (o.dlShiftTo(g, v), i.subTo(v, i); i[f] < --y; ) i.subTo(v, i)
          }
          null != e && (i.drShiftTo(c, e), a != s && E.ZERO.subTo(e, e)),
            (i.t = c),
            i.clamp(),
            0 < h && i.rShiftTo(h, i),
            a < 0 && E.ZERO.subTo(i, i)
        }
      }
    }),
    (E.prototype.invDigit = function() {
      if (this.t < 1) return 0
      var t = this[0]
      if (0 == (1 & t)) return 0
      var e = 3 & t
      return 0 <
        (e =
          ((e =
            ((e = ((e = (e * (2 - (15 & t) * e)) & 15) * (2 - (255 & t) * e)) & 255) *
              (2 - (((65535 & t) * e) & 65535))) &
            65535) *
            (2 - ((t * e) % this.DV))) %
          this.DV)
        ? this.DV - e
        : -e
    }),
    (E.prototype.isEven = function() {
      return 0 == (0 < this.t ? 1 & this[0] : this.s)
    }),
    (E.prototype.exp = function(t, e) {
      if (4294967295 < t || t < 1) return E.ONE
      var i = b(),
        n = b(),
        r = e.convert(this),
        o = V(t) - 1
      for (r.copyTo(i); 0 <= --o; )
        if ((e.sqrTo(i, n), 0 < (t & (1 << o)))) e.mulTo(n, r, i)
        else {
          var a = i
          ;(i = n), (n = a)
        }
      return e.revert(i)
    }),
    (E.prototype.toString = function(t) {
      if (this.s < 0) return '-' + this.negate().toString(t)
      var e
      if (16 == t) e = 4
      else if (8 == t) e = 3
      else if (2 == t) e = 1
      else if (32 == t) e = 5
      else {
        if (4 != t) return this.toRadix(t)
        e = 2
      }
      var i,
        n = (1 << e) - 1,
        r = !1,
        o = '',
        a = this.t,
        s = this.DB - ((a * this.DB) % e)
      if (0 < a--)
        for (s < this.DB && 0 < (i = this[a] >> s) && ((r = !0), (o = h(i))); 0 <= a; )
          s < e
            ? ((i = (this[a] & ((1 << s) - 1)) << (e - s)), (i |= this[--a] >> (s += this.DB - e)))
            : ((i = (this[a] >> (s -= e)) & n), s <= 0 && ((s += this.DB), --a)),
            0 < i && (r = !0),
            r && (o += h(i))
      return r ? o : '0'
    }),
    (E.prototype.negate = function() {
      var t = b()
      return E.ZERO.subTo(this, t), t
    }),
    (E.prototype.abs = function() {
      return this.s < 0 ? this.negate() : this
    }),
    (E.prototype.compareTo = function(t) {
      var e = this.s - t.s
      if (0 != e) return e
      var i = this.t
      if (0 != (e = i - t.t)) return this.s < 0 ? -e : e
      for (; 0 <= --i; ) if (0 != (e = this[i] - t[i])) return e
      return 0
    }),
    (E.prototype.bitLength = function() {
      return this.t <= 0 ? 0 : this.DB * (this.t - 1) + V(this[this.t - 1] ^ (this.s & this.DM))
    }),
    (E.prototype.mod = function(t) {
      var e = b()
      return (
        this.abs().divRemTo(t, null, e), this.s < 0 && 0 < e.compareTo(E.ZERO) && t.subTo(e, e), e
      )
    }),
    (E.prototype.modPowInt = function(t, e) {
      var i
      return (i = t < 256 || e.isEven() ? new l(e) : new m(e)), this.exp(t, i)
    }),
    (E.ZERO = s(0)),
    (E.ONE = s(1)),
    (u.prototype.init = function(t) {
      var e, i, n
      for (e = 0; e < 256; ++e) this.S[e] = e
      for (e = i = 0; e < 256; ++e)
        (i = (i + this.S[e] + t[e % t.length]) & 255),
          (n = this.S[e]),
          (this.S[e] = this.S[i]),
          (this.S[i] = n)
      ;(this.i = 0), (this.j = 0)
    }),
    (u.prototype.next = function() {
      var t
      return (
        (this.i = (this.i + 1) & 255),
        (this.j = (this.j + this.S[this.i]) & 255),
        (t = this.S[this.i]),
        (this.S[this.i] = this.S[this.j]),
        (this.S[this.j] = t),
        this.S[(t + this.S[this.i]) & 255]
      )
    })
  var d,
    p,
    f,
    g = 256
  function v() {
    var t
    ;(t = new Date().getTime()),
      (p[f++] ^= 255 & t),
      (p[f++] ^= (t >> 8) & 255),
      (p[f++] ^= (t >> 16) & 255),
      (p[f++] ^= (t >> 24) & 255),
      g <= f && (f -= g)
  }
  if (null == p) {
    var y
    if (((p = new Array()), (f = 0), window.crypto && window.crypto.getRandomValues)) {
      var w = new Uint8Array(32)
      for (window.crypto.getRandomValues(w), y = 0; y < 32; ++y) p[f++] = w[y]
    }
    if ('Netscape' == navigator.appName && navigator.appVersion < '5' && window.crypto) {
      var U = window.crypto.random(32)
      for (y = 0; y < U.length; ++y) p[f++] = 255 & U.charCodeAt(y)
    }
    for (; f < g; ) (y = Math.floor(65536 * Math.random())), (p[f++] = y >>> 8), (p[f++] = 255 & y)
    ;(f = 0), v()
  }
  function D() {
    if (null == d) {
      for (v(), (d = new u()).init(p), f = 0; f < p.length; ++f) p[f] = 0
      f = 0
    }
    return d.next()
  }
  function A() {}
  function B() {
    ;(this.n = null),
      (this.e = 0),
      (this.d = null),
      (this.p = null),
      (this.q = null),
      (this.dmp1 = null),
      (this.dmq1 = null),
      (this.coeff = null)
  }
  ;(A.prototype.nextBytes = function(t) {
    var e
    for (e = 0; e < t.length; ++e) t[e] = D()
  }),
    (B.prototype.doPublic = function(t) {
      return t.modPowInt(this.e, this.n)
    }),
    (B.prototype.setPublic = function(t, e) {
      null != t && null != e && 0 < t.length && 0 < e.length
        ? ((this.n = new E(t, 16)), (this.e = parseInt(e, 16)))
        : alert('Invalid RSA public key')
    })
  var T,
    C,
    _ = !(B.prototype.encrypt = function(t) {
      var e = (function(t, e) {
        if (e < t.length + 11) return alert('Message too long for RSA'), null
        for (var i = new Array(), n = t.length - 1; 0 <= n && 0 < e; ) {
          var r = t.charCodeAt(n--)
          i[--e] =
            r < 128
              ? r
              : 127 < r && r < 2048
              ? ((i[--e] = (63 & r) | 128), (r >> 6) | 192)
              : ((i[--e] = (63 & r) | 128), (i[--e] = ((r >> 6) & 63) | 128), (r >> 12) | 224)
        }
        i[--e] = 0
        for (var o = new A(), a = new Array(); 2 < e; ) {
          for (a[0] = 0; 0 == a[0]; ) o.nextBytes(a)
          i[--e] = a[0]
        }
        return (i[--e] = 2), (i[--e] = 0), new E(i)
      })(t, (this.n.bitLength() + 7) >> 3)
      if (null == e) return null
      var i = this.doPublic(e)
      if (null == i) return null
      var n = i.toString(16)
      return 0 == (1 & n.length) ? n : '0' + n
    }),
    k = !1,
    S =
      'BF6F5A3B7937B0FCA7A7A205ED1FEF3D80D3423F8B49E4C6FB64EBFF3F49C7B2F62E33DDA6F4C5D0AB3926E4A0BD79C31AEF48DF5C8B67F935188091DCFA44109153F8CEEC10C813E70D78A8FBDC1633CC8364A359120BC9413539075D333D47FF2F7D9EC00E5A9CB54C5473870A68B8ADB0A6928053F41910418F2DED2948218D6C79A01FBD3189879546A509DD8076A411E359C8403D5423FF31081D6D3E699F53DED35BB2D8CBD82A233DE64A39217C87C8558953CE206B2DCE17E81B29189CA3EC0C92A0773C4A9A75D0099A16621255C2583E47B02EDB37082DC3BB7343E21B897D3DDE6CC4ED10A43AD9EBDEB28FD7FE143ED1E10DD3F797C0F25D9DFD',
    F = '10001'
  function M(t) {
    if (VotematchEU.config.style) {
      var e = q('VotematchEU-style')
      e
        ? t && e.setAttribute('href', VotematchEU.config.style)
        : ((e = document.createElement('link')).setAttribute('rel', 'stylesheet'),
          e.setAttribute('type', 'text/css'),
          e.setAttribute('href', VotematchEU.config.style),
          (e.id = 'VotematchEU-style'),
          q('head').appendChild(e))
    }
  }
  function j(t) {
    var e = !1,
      i = VotematchEU.dict[VotematchEU.config.lang].agree_embed
    ;(i = i.replace('<a>', '<a href="' + VotematchEU.config.privacy + '" target="_blank">')),
      (VotematchEU.dict[VotematchEU.config.lang].agree_embed = i)
    var n = q('VotematchEU-dialog')
    n ||
      ((e = !0),
      ((n = document.createElement('div')).id = 'VotematchEU-dialog'),
      n.setAttribute('style', 'display:none')),
      (t || e) &&
        (VotematchEU.config.crypto
          ? (content = VotematchEU.crtemplate)
          : (content = VotematchEU.pltemplate),
        VotematchEU.dict[VotematchEU.config.lang] &&
          (content = content.replace(/{{(\w+)}}/g, function(t, e) {
            return VotematchEU.dict[VotematchEU.config.lang][e] || t
          })),
        (n.innerHTML = content)),
      e && q('body').appendChild(n)
  }
  function x(t) {
    var e = (function(t) {
      var e = []
      if (
        !(e = document.getElementsByClassName(t)).length &&
        !(e = document.getElementsByTagName(t)).length
      ) {
        var i = document.getElementById(t)
        i && (e = [i])
      }
      return e
    })('VotematchEU-button')
    if (e.length) {
      void 0 === t && (t = z())
      for (var i = 0; i < e.length; i++)
        e[i].setAttribute('onclick', 'VotematchEU.popup()'),
          (e[i].style.visibility = t ? 'visible' : 'hidden')
    }
  }
  function z() {
    if (!_) return I('validate: settings pending..'), !1
    if (!k) return I('validate: results pending..'), !1
    if (VotematchEU.error) return O(VotematchEU.error), !1
    if (
      (VotematchEU.dict[VotematchEU.config.lang] ||
        (I('unknown language: ' + VotematchEU.config.lang), (VotematchEU.config.lang = 'en')),
      !VotematchEU.data.country)
    )
      return O('no country given'), !1
    var t = Object.keys(VotematchEU.data.answers).length
    return t < VotematchEU.config.qreq
      ? (O('not enough answers given: ' + t), !1)
      : (R('Result validated.'), !0)
  }
  function N(t) {
    var e = void 0 !== t.answers
    for (var i in t) {
      if (0 === i.indexOf('answer'))
        if ((o = i.match(/answer(\d+)/)) && 2 == o.length) {
          if (!e) {
            t.answers = []
            for (var n = 0; n < VotematchEU.config.qmax; n++) t.answers[n] = null
            e = !0
          }
          var r = 1 * o[1]
          0 < r && r <= VotematchEU.config.qmax
            ? (t.answers[r - 1] = t[i])
            : O('invalid answer id : ' + r),
            delete t[i]
        }
      'bestmatch' == i && ((t.parties = t[i]), delete t[i]),
        'bestscore' == i && ((t.score = t[i]), delete t[i])
    }
    if (
      (void 0 !== t.country && (t.country = t.country.toLowerCase()),
      void 0 !== t.lang &&
        ((t.lang = t.lang.toLowerCase()),
        void 0 === VotematchEU.dict[t.lang] &&
          (I('unknown language : ' + t.lang), (t.lang = 'en'))),
      void 0 !== t.parties)
    ) {
      'string' == typeof t.parties && (t.parties = t.parties.split(',')),
        'number' == typeof t.parties && (t.parties = ['' + t.parties])
      for (n = 0; n < t.parties.length; n++) {
        var o
        if ('string' != typeof t.parties[n]) O('invalid party id : ' + t.parties[n])
        else
          (t.parties[n] = t.parties[n].toLowerCase()),
            (o = t.parties[n].match(/\w\w(\d+)/)) ||
              (O('invalid party id : ' + t.parties[n]), delete t.parties[n])
      }
    }
    if (void 0 !== t.score) {
      var a = t.score
      ;(t.score = 1 * t.score), isNaN(t.score) && (O('invalid score : ' + a), delete t.score)
    }
    if (void 0 !== t.answers) {
      'string' == typeof t.answers && (t.answers = t.answers.split(','))
      for (n = 0; n < t.answers.length; n++)
        'yes' === t.answers[n] ||
        'true' === t.answers[n] ||
        '1' === t.answers[n] ||
        !0 === t.answers[n]
          ? (t.answers[n] = 1)
          : 'no' === t.answers[n] ||
            'false' === t.answers[n] ||
            '-1' === t.answers[n] ||
            !1 === t.answers[n]
          ? (t.answers[n] = -1)
          : 'neither' === t.answers[n] || '0' === t.answers[n]
          ? (t.answers[n] = 0)
          : void 0 === t.answers[n] ||
            '' === t.answers[n] ||
            'null' === t.answers[n] ||
            '-' === t.answers[n]
          ? (t.answers[n] = null)
          : -1 !== t.answers[n] &&
            0 !== t.answers[n] &&
            1 !== t.answers[n] &&
            null !== t.answers[n] &&
            (O('invalid answer value: ' + n + ':' + t.answers[n]), delete t.parties[n])
    }
    return t
  }
  function R(t) {
    VotematchEU.config.debug && window.console && console.log('VotematchEU', t)
  }
  function I(t) {
    t && window.console && console.info('VotematchEU', t)
  }
  function O(t) {
    ;(VotematchEU.error = t) && window.console && console.error('VotematchEU', t)
  }
  function q(t) {
    var e = null
    if (!(e = document.getElementById(t))) {
      var i = document.getElementsByTagName(t)
      i.length && (e = i[0]), e || ((i = document.getElementsByClassName(t)).length && (e = i[0]))
    }
    return e
  }
  function L(t, e, i) {
    var n = q(t)
    void 0 === i && (i = -1 == n.className.indexOf(e)),
      n &&
        (i
          ? -1 == n.className.indexOf(e) && (n.className += ' ' + e)
          : (n.className = n.className.replace(' ' + e, '').replace(e, '')))
  }
  function P(t, e) {
    for (var i in e) e.hasOwnProperty(i) && (t[i] = e[i])
    return t
  }
  ;(window.VotematchEU = {
    config: {
      debug: !1,
      crypto: !0,
      action: '',
      target: '',
      style: '',
      lang: 'en',
      qreq: 10,
      qmax: 16,
      privacy: '#'
    },
    data: { country: '', parties: [], score: 0, answers: [] },
    dict: {},
    crtemplate: '',
    pltemplate: '',
    ready: !1,
    error: '',
    settings: function(t) {
      ;(t = N(t)), P(this.config, t), (_ = !0)
    },
    update: function(t) {
      ;(t = N(t)), P(this.config, t), t.style && M(!0), I('update received.'), j(!0)
    },
    results: function(t, e) {
      R(t), (t = N(t)), P(this.data, t), (k = !0), e || x()
    },
    popup: function() {
      this.ready
        ? (L('VotematchEU-dialog', 'VotematchEU-active', !0),
          q('VotematchEU-dialog').setAttribute('aria-hidden', 'false'))
        : O('popup called before ready')
    },
    hide: function() {
      this.ready
        ? (L('VotematchEU-dialog', 'VotematchEU-active', !1),
          q('VotematchEU-dialog').setAttribute('aria-hidden', 'true'))
        : O('hide called before ready')
    },
    agree: function(t) {
      var e = q('VotematchEU-content-btn')
      t ? e.removeAttribute('disabled') : e.setAttribute('disabled', 'disabled')
    },
    submit: function(t) {
      if (t && z()) {
        var e = q('VotematchEU-form')
        if (
          (e.setAttribute('action', this.config.action),
          e.setAttribute('target', this.config.target),
          this.config.crypto)
        ) {
          var i = JSON.stringify({
            lang: this.config.lang,
            country: this.data.country,
            parties: this.data.parties,
            score: this.data.score,
            answers: this.data.answers
          })
          e.elements.crypto.value = this.encrypt(i)
        } else
          (e.elements.lang.value = this.config.lang),
            (e.elements.country.value = this.data.country),
            (e.elements.parties.value = this.data.parties.join(',')),
            (e.elements.score.value = this.data.score),
            (e.elements.answers.value = this.data.answers.join(','))
        if (this.config.debug) {
          for (var n = {}, r = 0; r < e.elements.length; r++)
            e.elements[r].name && (n[e.elements[r].name] = e.elements[r].value)
          R(n)
        }
        e.submit()
      }
    },
    encrypt: function(t) {
      var e = new B()
      e.setPublic(S, F)
      var i = e.encrypt(t)
      return (
        !!i &&
        ((encrypted = (function(t, e) {
          for (var i = '', n = 0; n + e < t.length; ) (i += t.substring(n, n + e) + '\n'), (n += e)
          return i + t.substring(n, t.length)
        })(
          (function(t) {
            var e,
              i,
              n = ''
            for (e = 0; e + 3 <= t.length; e += 3)
              (i = parseInt(t.substring(e, e + 3), 16)), (n += r.charAt(i >> 6) + r.charAt(63 & i))
            for (
              e + 1 == t.length
                ? ((i = parseInt(t.substring(e, e + 1), 16)), (n += r.charAt(i << 2)))
                : e + 2 == t.length &&
                  ((i = parseInt(t.substring(e, e + 2), 16)),
                  (n += r.charAt(i >> 2) + r.charAt((3 & i) << 4)));
              0 < (3 & n.length);

            )
              n += o
            return n
          })(i),
          64
        )),
        encrypted)
      )
    }
  }),
    (VotematchEU.pltemplate =
      '<div id="VotematchEU-dialog-wrapper"><div class="VotematchEU-backdrop" onclick="VotematchEU.hide()"></div><div role="dialog" class="VotematchEU-content"><form method="post" id="VotematchEU-form"><input type="hidden" name="crypto" value=""><input type="hidden" name="lang" value=""><input type="hidden" name="country" value=""><input type="hidden" name="parties" value=""><input type="hidden" name="score" value=""><input type="hidden" name="answers" value=""><header><i class="VotematchEU-close" onclick="VotematchEU.hide()">&#x2715;</i><a href="https://votematch.eu" class="VotematchEU-logo-wrapper"><img src="//assets.votematch.eu/header.svg" alt="VoteMatchEurope" class="VotematchEU-logo"></a></header><main class="VotematchEU-main"><section class="VotematchEU-header"><h3>{{title_embed}}</h3><p><strong>{{intro_embed}}</strong></p></section><section class="VotematchEU-terms"><label><input name="agree" value="yes" type="checkbox" onchange="VotematchEU.agree(this.checked);">{{agree_embed}}</label></section><section class="VotematchEU-cta"><button type="button" onclick="VotematchEU.submit(this.form.elements.agree.checked)" class="VotematchEU-content-btn" disabled="disabled">{{submit_embed}}</button></section></main></form></div> </div>'),
    (VotematchEU.crtemplate =
      '<div id="VotematchEU-dialog-wrapper"><div class="VotematchEU-backdrop" onclick="VotematchEU.hide()"></div><div role="dialog" class="VotematchEU-content"><form method="post" id="VotematchEU-form"><input type="hidden" name="crypto" value=""><header><i class="VotematchEU-close" onclick="VotematchEU.hide()">&#x2715;</i><a href="https://votematch.eu" class="VotematchEU-logo-wrapper"><img src="//assets.votematch.eu/header.svg" alt="VoteMatchEurope" class="VotematchEU-logo"></a></header><main class="VotematchEU-main"><section class="VotematchEU-header"><h3>{{title_embed}}</h3><p><strong>{{intro_embed}}</strong></p></section><section class="VotematchEU-terms"><label><input name="agree" value="yes" type="checkbox" onchange="VotematchEU.agree(this.checked);">{{agree_embed}}</label></section><section class="VotematchEU-cta"><button type="button" onclick="VotematchEU.submit(this.form.elements.agree.checked)" class="VotematchEU-content-btn" disabled="disabled">{{submit_embed}}</button></section></main></form></div> </div>'),
    (VotematchEU.dict = {
      en: {
        title_embed: 'What party in the EU is your best match?',
        intro_embed:
          'Compare your results with political parties in other European countries. You will be redirected to the VoteMatch Europe website.',
        agree_embed:
          'I hereby agree to transfer my results to votematch.eu under the requirements as set in its\n<a>privacy statement of votematch.eu</a> in order to compare my answers with political parties in other member states. ',
        agree_err_embed: 'You need to agree to the transfer of your results.',
        submit_embed: 'See results'
      },
      hu: {
        title_embed: 'Az EU-ban melyik párttal értek egyet a leginkább?',
        intro_embed:
          'Hasonlítsd össze az eredményed más európai ország pártjaival. Átirányítunk a VoteMatch Europe weboldalára.',
        agree_embed:
          'Beleegyezek abba, hogy annak érdekében, hogy a válaszaimat más tagállamok pártjaival össze tudjam hasonlítani, az adataimat a votematch.eu oldalára történő, az <a>adatvédelmi nyilatkozatban</a> foglaltak alapján átirányítják a votematch.eu oldalára.',
        agree_err_embed: 'A beleegyezésed szükséges, ahhoz, hogy az eredményedet átirányítsuk.',
        submit_embed: 'Mutasd az eredményem'
      },
      nl: {
        title_embed: 'Welke partij in de EU past het best bij u?',
        intro_embed:
          'Vergelijk uw resultaten met politieke partijen in andere Europese lidstaten. U wordt doorgestuurd naar de website van VoteMatch Europe.',
        agree_embed:
          'Ik geef toestemming dat mijn resultaten worden doorgestuurd naar votematch.eu, onder de voorwaarden zoals beschreven in het <a>privacystatement van votematch.eu</a>, zodat mijn antwoorden kunnen worden vergelijken met politieke partijen in andere landen.',
        agree_err_embed:
          'U moet toestemming geven voor het doorsturen van uw resultaten om door te gaan.',
        submit_embed: 'Bekijk resultaten'
      },
      bg: {
        title_embed: 'Коя партия в ЕС е най-близо до вас?',
        intro_embed:
          'Сравнете вашите отговори с тези на политически партии в други страни от ЕС. Ще бъдете пренасочени към сайта VoteMatch Europe.',
        agree_embed:
          'Давам съгласие резултатите ми да бъдат прехвърлени към votematch.eu според <a>правилата за поверителност</a> на votematch.eu с цел моите позиции да бъдат сравнявани с тези на политически партии от друго страни от ЕС.',
        agree_err_embed: 'Трябва да дадете съгласие за прехвърляне на вашите резултати.',
        submit_embed: 'Вижте резултатите'
      },
      de: {
        title_embed: 'Welche Partei in der EU steht Ihnen am nächsten?',
        intro_embed:
          'Vergleichen Sie Ihre Ergebnisse mit den politischen Parteien in anderen europäischen Ländern. Dazu werden Sie nun auf die Website von VoteMatch Europe weitergeleitet.',
        agree_embed:
          'Hiermit erkläre ich mich einverstanden, meine Ergebnisse gemäß <a>den beschriebenen Vorgehen</a> an votematch.eu zu übertragen.',
        agree_err_embed: 'Sie müssen der Übertragung Ihrer Ergebnisse zustimmen.',
        submit_embed: 'Ergebnisse anzeign'
      },
      el: {
        title_embed: 'Ποιο κόμμα στην ΕΕ σας εκφράζει καλύτερα;',
        intro_embed:
          'Συγκρίνετε τις απαντήσεις σας με τις θέσεις πολιτικών κομμάτων σε άλλες ευρωπαϊκές χώρες. Θα ανακατευθυνθείτε στον ιστότοπο του VoteMatch Europe.',
        agree_embed:
          'Συμφωνώ να μεταφέρω τις απαντήσεις μου στο votematch.eu σύμφωνα με τις απαιτήσεις που ορίζονται στη δήλωση προστασίας <a>προσωπικών δεδομένων του votematch.eu</a>, προκειμένου να συγκρίνω τις απαντήσεις μου με τα πολιτικά κόμματα άλλων κρατών μελών.',
        agree_err_embed: 'Πρέπει να συμφωνήσετε με τη μεταφορά των απαντήσεών σας.',
        submit_embed: 'Δείτε τα αποτελέσματα'
      }
    }),
    (T = {
      debug: !1,
      action: 'https://app.votematch.eu',
      target: '_blank',
      style: '//assets.votematch.eu/embed.css',
      lang: 'en',
      privacy: 'https://votematch.eu/privacy-policy/'
    }),
    VotematchEU.settings(T, !0),
    (C = function() {
      !(function() {
        var t = q('VotematchEU-settings')
        if (t) {
          for (var e = {}, i = 0; i < t.elements.length; i++)
            e[t.elements[i].name] = t.elements[i].value
          VotematchEU.settings(e)
        }
      })(),
        (function() {
          var t = q('VotematchEU-results')
          if (t) {
            for (var e = {}, i = 0; i < t.elements.length; i++)
              e[t.elements[i].name] = t.elements[i].value
            VotematchEU.results(e, !0)
          }
        })(),
        M(),
        j(),
        x(),
        (function() {
          VotematchEU.ready = !0
          var t = null
          'function' == typeof Event
            ? (t = new Event('VotematchEU-ready'))
            : (t = document.createEvent('Event')).initEvent('VotematchEU-ready', !0, !0),
            document.dispatchEvent(t)
        })()
    }),
    'loading' != document.readyState
      ? C()
      : document.addEventListener
      ? document.addEventListener('DOMContentLoaded', C)
      : document.attachEvent('onreadystatechange', function() {
          'complete' == document.readyState && C()
        })
})()
